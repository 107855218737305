import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { RiInformationLine } from "@react-icons/all-files/ri/RiInformationLine";

import {
    RHFormInput,
    SelectOption,
    NewGrid as Grid,
    RHNumericInput,
    RHSelectInput,
    RHCheckBoxInput,
    ToolTip,
    Box
} from "Atoms";
import { Languagekey, useLanguage } from "LanguageProvider";
import { DiscountWithMenus } from "../utils/DiscountTypes";
import { PosTypes } from "Constants";
import { DiscountFormDates } from "./DiscountFormDates";
import { isSuperAdmin } from "Utils";
import { useQopla } from "Providers";

type Props = {
    shopOptions: SelectOption[];
    eatingOptions: SelectOption[];
    disableFormInputs: boolean;
};

export const DiscountForm: React.FC<Props> = ({ shopOptions, eatingOptions, disableFormInputs }) => {
    const { translate } = useLanguage();
    const { authenticatedUser } = useQopla();
    const {
        control,
        formState: { errors },
        setValue,
        getValues,
        trigger
    } = useFormContext<DiscountWithMenus>();

    const { fixedDiscount: isFixedDiscount } = getValues();
    const _isSuperAdmin = isSuperAdmin(authenticatedUser.roles);

    /** Have to watch a few properties here due to the validation
     * All these watches are disabled if this is a fixed discount
     * In normal discount - changing these values also need to validate other inputs
     * See Trigger examples below
     */
    const amount = useWatch({ control, name: "amount", disabled: isFixedDiscount });
    const rate = useWatch({ control, name: "rate", disabled: isFixedDiscount });
    const code = useWatch({ control, name: "code", disabled: isFixedDiscount });
    const availableOn = useWatch({ control, name: "availableOn", disabled: isFixedDiscount });
    const pickUpSelection = useWatch({ control, name: "eatingOptions", disabled: isFixedDiscount });

    const hasApplicationError = !!errors.canApplyOnWholeOrder;
    const hasAmountDiscount = !!amount && amount > 0;
    const hasCashRegisterSelected = availableOn?.includes(PosTypes.POS) ?? false;
    const hasRateDiscount = !!rate && rate > 0;

    /**
     * Included Express in here but label is V2
     */
    const availableOnOptions = [PosTypes.ONLINE, PosTypes.POS, PosTypes.EXPRESS].map(type => {
        if (type === PosTypes.EXPRESS) {
            return { label: `${translate("expressPOS")} V2`, value: type };
        }
        return { label: translate(type as Languagekey), value: type };
    });

    /**
     * [TRIGGERS]
     * This component has alot of validation
     * So validation needs to be re-triggered (only on applied discounts)
     * 1. If you select the discount to be available online then you must have a code
     * 2. Amount or Rate needs to be above 0 - will disable either or depending on which one used
     * 3. If it is an online discount it needs to have at least one eating option
     * 4. If code is deleted then if it is a email discount it will triger the validation
     */
    useEffect(() => {
        if (!isFixedDiscount && (hasAmountDiscount || hasRateDiscount)) {
            trigger(["rate", "amount"]);
        }
    }, [rate, amount]);

    useEffect(() => {
        if (!isFixedDiscount) {
            trigger(["availableOn", "code", "eatingOptions", "allowedEmails"]);
        }
    }, [code, availableOn, pickUpSelection]);

    return (
        <>
            <RHFormInput
                name="name"
                fullWidth
                formLabel={translate("name")}
                control={control}
                placeholder={translate("name")}
                isMandatory
            />
            {!isFixedDiscount && (
                <RHFormInput
                    name="code"
                    fullWidth
                    formLabel={translate("discountCode")}
                    placeholder={translate("discountCode")}
                    control={control}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setValue("code", event.target.value.toUpperCase().trim(), { shouldDirty: true })
                    }
                    isDisabled={disableFormInputs}
                    tooltipLabelText={translate("toBeUsedOnlineAndExpress")}
                    showExtendedLabelText
                    wrapperProps={{ fontWeight: 600 }}
                />
            )}

            <Grid templateColumns={isFixedDiscount ? "1fr" : "1fr 1fr"} gap="1em">
                {!isFixedDiscount && (
                    <RHNumericInput
                        control={control}
                        name="amount"
                        fullWidth
                        min={0}
                        placeholder={translate("amountAlt")}
                        formLabel={translate("amountAlt")}
                        isDisabled={hasRateDiscount || disableFormInputs}
                    />
                )}
                <RHNumericInput
                    control={control}
                    name="rate"
                    fullWidth
                    min={0}
                    placeholder={translate("percentage")}
                    formLabel={translate("percentage")}
                    isDisabled={hasAmountDiscount || disableFormInputs}
                />
            </Grid>
            {!isFixedDiscount && (
                <RHSelectInput
                    formLabel={translate("availableOn")}
                    placeholder={translate("availableOn")}
                    noOptionsMessage={() => translate("noAlternative")}
                    name="availableOn"
                    options={availableOnOptions}
                    isMulti
                    control={control}
                    isDisabled={disableFormInputs}
                    isMandatory
                />
            )}

            <RHSelectInput
                formLabel={translate("associatedRestaurants")}
                placeholder={translate("associatedRestaurants")}
                noOptionsMessage={() => translate("noAlternative")}
                name="shopIds"
                options={shopOptions}
                isMulti
                isClearable={!disableFormInputs}
                control={control}
                isMandatory
            />
            <RHSelectInput
                formLabel={translate("pickUpSelection")}
                placeholder={translate("pickUpSelection")}
                noOptionsMessage={() => translate("noAlternative")}
                name="eatingOptions"
                options={eatingOptions}
                isMulti
                isDisabled={disableFormInputs}
                isClearable={!disableFormInputs}
                control={control}
            />
            {!isFixedDiscount && (
                <>
                    <DiscountFormDates isDisabled={disableFormInputs} />
                    <RHFormInput
                        name="onlineCustomerMessage"
                        fullWidth
                        formLabel={`${translate("onlineMessage")} ${translate("appearsAfterCode")}`}
                        placeholder={translate("onlineMessage")}
                        maxLength={75}
                        isDisabled={!code && !rate}
                        control={control}
                    />
                    {hasAmountDiscount && hasCashRegisterSelected && (
                        <Grid templateColumns="1fr 1fr">
                            <RHCheckBoxInput
                                formLabel={`${translate("discountAmountPerProduct")} (${translate("cashRegister")})`}
                                name="canApplyOnEachProduct"
                                id="canApplyOnEachProduct"
                                control={control}
                                isDisabled={disableFormInputs}
                                isMandatory={hasApplicationError}
                            />
                            <RHCheckBoxInput
                                formLabel={translate("discountAmountPerTotal")}
                                name="canApplyOnWholeOrder"
                                id="canApplyOnWholeOrder"
                                control={control}
                                isDisabled={disableFormInputs}
                                isMandatory={hasApplicationError}
                            />
                        </Grid>
                    )}
                </>
            )}

            {isFixedDiscount && (
                <Grid templateColumns="auto 1fr">
                    <RHCheckBoxInput
                        formLabel={translate("ableToCombineDiscounts")}
                        name="combineWithOtherDiscounts"
                        id="combineWithOtherDiscounts"
                        control={control}
                    />
                    <ToolTip
                        m={1}
                        placement={isMobile ? "top" : "bottom"}
                        text={translate("ifSelectedCanUseOtherDiscounts")}
                    >
                        <Box as={RiInformationLine} size="20px" color="gray.500" />
                    </ToolTip>
                </Grid>
            )}
            <RHCheckBoxInput
                formLabel={translate("activateDiscount")}
                name="disabled"
                id="disabled"
                control={control}
                isDisabled={disableFormInputs}
            />
            {isFixedDiscount && _isSuperAdmin && (
                <RHCheckBoxInput
                    formLabel={translate("allowOnlySuperAdmin")}
                    name="onlySuperAdminCanViewAndEdit"
                    id="onlySuperAdminCanViewAndEdit"
                    control={control}
                    isDisabled={disableFormInputs}
                />
            )}
        </>
    );
};
