import React from "react";
import { useFormContext } from "react-hook-form";

import { useLanguage } from "Providers";
import { ComboDiscountWithSelections } from "../utils/comboDiscountFormTypes";
import { RHFormInput, RHSelectInput, RHTextAreaInput, RHCheckBoxInput, SelectOption } from "Atoms";
import { ComboDiscountFormDates } from "./ComboDiscountFormDates";

type Props = {
    shopOptions: SelectOption[];
    disableFormInputs: boolean;
}

export const ComboDiscountForm: React.FC<Props> = ({ shopOptions, disableFormInputs }) => {
    const { translate } = useLanguage();
    const { control } = useFormContext<ComboDiscountWithSelections>();

    return (
        <>
            <RHCheckBoxInput
                formLabel={translate("activateDiscount")}
                name="disabled"
                id="disabled"
                control={control}
                isDisabled={disableFormInputs}
            />
            <RHFormInput
                name="name"
                fullWidth
                formLabel={translate("name")}
                control={control}
                placeholder={translate("name")}
                isMandatory
            />
            <RHTextAreaInput name="description" formLabel={translate("description")} fullWidth control={control} />
            <RHSelectInput
                formLabel={translate("associatedRestaurants")}
                placeholder={translate("associatedRestaurants")}
                noOptionsMessage={() => translate("noAlternative")}
                name="shopIds"
                options={shopOptions}
                isMulti
                isClearable={!disableFormInputs}
                control={control}
                isMandatory
            />
            <ComboDiscountFormDates isDisabled={disableFormInputs} />
        </>
    );
};