import React from "react";
import moment from "moment";
import { FaCalendar } from "@react-icons/all-files/fa/FaCalendar";

import { Text, Box } from "Atoms";
import { isTodayBeforeDate, isDateOnOrBetweenDates } from "Utils";
import { useLanguage } from "LanguageProvider";

/**NOTE in test suite */
export const onCheckDiscountCodeDateRange = (startDate?: string, endDate?: string, format?: string): Boolean => {
    if (startDate && endDate) {
        return isDateOnOrBetweenDates(
            moment(),
            moment(startDate, format).startOf("day"),
            moment(endDate, format).endOf("day")
        );
    }
    if (startDate) {
        return moment().isSameOrAfter(startDate);
    }

    return true;
};

type Date = {
    startDate?: string;
    endDate?: string;
};

export const DiscountDate: React.FC<Date> = ({ startDate, endDate }) => {
    const { translate } = useLanguage();
    const displayDateFormat = "YYYY-MM-DD";
    const dateRangeFormat = "YYYY-MM-DDTHH:mm";

    return (
        <>
            {startDate && (
                <Text>
                    {onCheckDiscountCodeDateRange(startDate, endDate, dateRangeFormat) ? (
                        <Box as={FaCalendar} size="10px" color="green.500" mr={2} />
                    ) : isTodayBeforeDate(startDate) ? (
                        <Box as={FaCalendar} size="10px" color="orange.500" mr={2} />
                    ) : (
                        <Box as={FaCalendar} size="10px" color="red.500" mr={2} />
                    )}
                    {moment(startDate).format(displayDateFormat)}
                    {endDate ? ` - ${moment(endDate).format(displayDateFormat)}` : ` - ${translate("ongoing")}`}
                </Text>
            )}
        </>
    );
};
