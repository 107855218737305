import React, { useEffect, useMemo } from "react";
import moment from "moment";
import { useFormContext, useWatch } from "react-hook-form";
import { flatMap } from "lodash";

import { Box, Flex, Indicator, RHSelectInput, SelectOption } from "Atoms";
import { DiscountWithMenus, MenuDiscountPeriod, ShopMenu } from "../utils/DiscountTypes";
import { DiscountMenu, MenuCategoryProducts } from "Types";
import { MenuProductsAndCategoriesTreeList } from "../../../shared";
import { useLanguage } from "LanguageProvider";
import { Tabs, Tab, TabList, TabPanels, TabPanel } from "Organisms";
import { DiscountFormDates } from "./DiscountFormDates";
import { isDateOnOrBetweenDates } from "Utils";
import { useEffectOnce } from "Hooks";

type Props = {
    shopMenus: ShopMenu[];
    allCompanyMenus: DiscountMenu[];
    shopIdsForLimitedAccess: string[];
    disableFormInputs: boolean;
    alcoholProductIds: string[];
    menuDiscountPeriods?: MenuDiscountPeriod[];
};

export const DiscountMenuForm: React.FC<Props> = ({
    shopMenus,
    allCompanyMenus,
    shopIdsForLimitedAccess,
    disableFormInputs,
    alcoholProductIds = [],
    menuDiscountPeriods = []
}) => {
    const { translate } = useLanguage();

    const { control, getValues, setValue } = useFormContext<DiscountWithMenus>();

    const { shopIds, fixedDiscount: isFixedDiscount, isACopy } = getValues();

    /** if form inputs aren't disabled user has full control */
    const hasFullControl = !disableFormInputs;

    /**
     * 1. Set a watch on menu selection and category and product ids
     * 2. Set a watch on start and end date - only if it is fixed discount
     * -- as 1 fixed discount can be set on a menu in a particular time period
     */
    const selectedMenuIds = useWatch({ control, name: "selectedMenuIds" });
    const menuCategoryAndProductIds = useWatch({ control, name: "menuCategoryAndProductIds" });
    const startDate = useWatch({ control, name: "startDate", disabled: !isFixedDiscount });
    const endDate = useWatch({ control, name: "endDate", disabled: !isFixedDiscount });

    /**
     * Get available and disallowed menus which are used to prevent some users with
     * less accessiblity adding or removing items from other menus
     * ** there are cases when discounts spread mutliple resaturants and users who only have access to
     * some of the shops - the can still add to the menu discount on menus that are no allowed
     */
    const { availableShopMenus, disallowedMenus } = shopMenus.reduce(
        (result: { availableShopMenus: string[]; disallowedMenus: string[] }, menu: ShopMenu) => {
            if (shopIds.includes(menu.shopId)) {
                if (shopIdsForLimitedAccess.includes(menu.shopId)) {
                    result.disallowedMenus = [...result.disallowedMenus, ...flatMap(menu.menus)] as string[];
                }
                result.availableShopMenus = [...result.availableShopMenus, ...flatMap(menu.menus)];
            }
            return result;
        },
        { availableShopMenus: [], disallowedMenus: [] }
    );

    /**
     * [FUNCTION] get all product ids under a category in a particular menu
     * @param categoryId specific id
     * @param menuId  id of the menu to look in
     */
    const getProductIdsUnderCategoryInMenu = (categoryId: string, menuId: string) => {
        const menu = allCompanyMenus.find(menu => menu.id === menuId);
        const category = menu?.menuProductCategories.find(cat => cat.id === categoryId);
        return [...(category?.menuBundleProducts ?? []), ...(category?.menuProducts ?? [])].map(product => product.id);
    };

    /**
     * [FUNCTION] check if every menu in all discounts (could have the same menu with different dates in different discounts)
     * @param menuId Current menu id
     * @returns if the menu is selectable according to the start / end dates provided in current discount
     */
    const checkIfMenuIsSelectable = (menuId: string): boolean => {
        return menuDiscountPeriods
            .filter(menuDiscountPeriod => menuDiscountPeriod.id === menuId)
            .every((menuDiscountPeriod: MenuDiscountPeriod) => {
                const startDateIsBetween = isDateOnOrBetweenDates(
                    moment(startDate),
                    menuDiscountPeriod.startDate,
                    menuDiscountPeriod.endDate!
                );
                const endDateIsBetween = isDateOnOrBetweenDates(
                    moment(endDate),
                    menuDiscountPeriod.startDate,
                    menuDiscountPeriod.endDate!
                );
                return startDateIsBetween || endDateIsBetween ? false : true;
            });
    };

    const checkIfMenuCanBeUsed = (menuId: string, menuName: string): SelectOption => {
        let isMenuSelectable = checkIfMenuIsSelectable(menuId);
        const isDisabledMenu = disallowedMenus?.includes(menuId) ?? false;
        let selectedShopsInDiscount = false;

        const disabledMenu =
            menuDiscountPeriods?.find((menuDiscountPeriod: MenuDiscountPeriod) => {
                if (menuDiscountPeriod.id === menuId) {
                    selectedShopsInDiscount = shopIds.some((value: string) =>
                        menuDiscountPeriod.shopIds.includes(value)
                    );
                    const startDateValidation = isDateOnOrBetweenDates(
                        moment(startDate),
                        menuDiscountPeriod.startDate,
                        menuDiscountPeriod.endDate!
                    );
                    const endDateValidation = isDateOnOrBetweenDates(
                        moment(endDate),
                        menuDiscountPeriod.startDate,
                        menuDiscountPeriod.endDate!
                    );
                    return startDateValidation || endDateValidation;
                }
                return false;
            }) ?? null;

        if (!selectedShopsInDiscount) {
            isMenuSelectable = true;
        }

        /** This is for fixing the menu if user doesn't have access to a particular shop so they menu becomes disabled but must remain fixed
         * Because they can alter certain parts of the discount
         */
        const isDisableAndFixed = !isMenuSelectable || isDisabledMenu;

        if (disabledMenu) {
            const start = moment(disabledMenu?.startDate).format("YYYY-MM-DD");
            const end = moment(disabledMenu?.endDate).endOf("day").format("YYYY-MM-DD");
            const menuLabel = `${menuName} - (${disabledMenu?.name} - ${start} - ${end} ) `;
            return {
                value: menuId,
                label: isMenuSelectable ? menuName : `${menuLabel}`,
                isDisabled: isDisableAndFixed,
                isFixed: isDisableAndFixed
            };
        }

        return {
            value: menuId,
            label: menuName,
            isDisabled: isDisableAndFixed,
            isFixed: isDisableAndFixed
        };
    };

    const menuOptions = useMemo(() => {
        if (!isFixedDiscount) {
            /** [MenuOptions] - options for selecting menu based on what is available
             * FILTER - only use menus that are available
             * REDUCE - return select options and set any disallowed as disabled & fixed
             * (when select menu ids are used they will be disabled in the select input, also can't be choosen on limited access)
             */
            return allCompanyMenus
                .filter(menu => availableShopMenus.includes(menu.id))
                .reduce((options: SelectOption[], menu: DiscountMenu) => {
                    const isADisabledMenu = disallowedMenus?.includes(menu.id) ?? false;
                    return [
                        ...options,
                        { value: menu.id, label: menu.name, isDisabled: isADisabledMenu, isFixed: isADisabledMenu }
                    ];
                }, []);
        }
        /**
         * Get menu selection for fixed discount based on the dates
         */
        const menuSelections = allCompanyMenus.reduce(
            (selection: SelectOption[], menu) =>
                availableShopMenus.includes(menu.id)
                    ? selection.concat(checkIfMenuCanBeUsed(menu.id, menu.name))
                    : selection,
            [] as SelectOption[]
        );

        return menuSelections;
    }, [startDate, endDate]);

    /**
     * [TOGGLE_FUNCTION] adds and removes categories from menus
     * if category is selected then the discount is applied to the whole of that category
     * @param categoryId selected id
     * @param isChecked state of the selection **Note it is oppostie so false = add
     * @param menuId menu id that the category belongs to
     */
    const onToggleMenuCategory = (categoryId: string, isChecked: boolean, menuId: string) => {
        const addCategory = !isChecked;
        if (addCategory) {
            /** Mapped - if menu id equals menu id passed in then add category to list and remove productids
             * if whole category was selected
             */
            const menuWithAddedCategory = menuCategoryAndProductIds.map(opt => {
                if (opt.menuId !== menuId) {
                    return opt;
                }
                const productIds = getProductIdsUnderCategoryInMenu(categoryId, menuId);
                return {
                    ...opt,
                    categoryIds: [...opt.categoryIds, categoryId],
                    productIds: opt.productIds.filter(id => !productIds.includes(id))
                };
            });
            setValue("menuCategoryAndProductIds", menuWithAddedCategory, { shouldDirty: true });
        } else {
            /** Mapped - if menu id equals menu id passed in then remove category and products */
            const menuWithoutCategory = menuCategoryAndProductIds.map(opt => {
                if (opt.menuId !== menuId) {
                    return opt;
                }
                const withRemovedCategory = opt.categoryIds.filter(cat => cat !== categoryId);
                const productIds = getProductIdsUnderCategoryInMenu(categoryId, menuId);
                return {
                    ...opt,
                    categoryIds: withRemovedCategory,
                    productIds: opt.productIds.filter(id => !productIds.includes(id))
                };
            });
            setValue("menuCategoryAndProductIds", menuWithoutCategory, { shouldDirty: true });
        }
    };

    /**
     * [TOGGLE_FUNCTION] on selection of single products or unselection
     * Used to target single products for a discount
     * @param productId selection id
     * @param isChecked is selected or not
     * @param menuId display menu
     */
    const onToggleMenuProduct = (productId: string, isChecked: boolean, menuId: string) => {
        const addProduct = !isChecked;
        if (addProduct) {
            const menuWithAddedProduct = menuCategoryAndProductIds.map(opt => ({
                ...opt,
                ...(opt.menuId === menuId && { productIds: [...opt.productIds, productId] })
            }));
            setValue("menuCategoryAndProductIds", menuWithAddedProduct, { shouldDirty: true });
        } else {
            const menuWithProductId = menuCategoryAndProductIds.map(opt => ({
                ...opt,
                productIds: opt.productIds.filter(id => id !== productId)
            }));
            setValue("menuCategoryAndProductIds", menuWithProductId, { shouldDirty: true });
        }
    };

    const menuHasSelectedItems = (menuCategoryProducts?: MenuCategoryProducts) => {
        return menuCategoryProducts
            ? menuCategoryProducts?.categoryIds?.length > 0 || menuCategoryProducts?.productIds?.length > 0
            : false;
    };

    const getMenuSelections = (menu: MenuCategoryProducts) =>
        allCompanyMenus.find((opt: DiscountMenu) => opt.id === menu.menuId)?.menuProductCategories ?? [];

    /**
     * After select menus will add to menuCategoryAndProductIds
     */
    useEffect(() => {
        if (!!selectedMenuIds?.length) {
            /** Get ids in menu option */
            const menuOptionIds = menuOptions.map(opt => opt.value) ?? [];
            const menuCategoryProducts = selectedMenuIds
                .filter(opt => menuOptionIds.includes(opt))
                .reduce((menus: MenuCategoryProducts[], id: string) => {
                    const menuSelected = menuCategoryAndProductIds?.find(opt => opt.menuId === id) ?? {
                        menuId: id,
                        categoryIds: [],
                        productIds: []
                    };
                    return [...menus, menuSelected];
                }, []);
            setValue("menuCategoryAndProductIds", menuCategoryProducts);
        }
    }, [selectedMenuIds]);

    useEffect(() => {
        if (isFixedDiscount && hasFullControl) {
            /** Final check if selected menu ids have a disabled menu then it needs to be removed from menu selection */
            const disabledMenuSelectionIds = menuOptions.filter(opt => opt.isDisabled).map(menu => menu.value);
            if (!!disabledMenuSelectionIds.length) {
                const allowedSelectedMenuIds: string[] =
                    selectedMenuIds?.filter(opt => !disabledMenuSelectionIds.includes(opt)) ?? [];
                if (allowedSelectedMenuIds.length !== selectedMenuIds?.length) {
                    setValue("selectedMenuIds", allowedSelectedMenuIds);
                }
            }
        }
    }, [menuOptions]);

    useEffectOnce(() => {
        if ((isFixedDiscount && hasFullControl) || isACopy) {
            /** Get ids in menu option */
            const menuOptionIds = menuOptions.map(opt => opt.value) ?? [];
            /** Get disabled menus in option */
            const disallowedMenus = menuOptions?.filter(menu => menu.isDisabled).map(menu => menu.value) ?? [];
            /** Get allowed menus for selection checking disallowed and included in option */
            const allowedSelectedMenus =
                selectedMenuIds?.filter(id => !disallowedMenus?.includes(id) && menuOptionIds.includes(id)) ?? [];
            /** Get menu category and products through allowed & options available */
            const newMenuCategoryIds =
                menuCategoryAndProductIds.filter(
                    menu => allowedSelectedMenus?.includes(menu.menuId) && menuOptionIds.includes(menu.menuId)
                ) ?? [];

            setValue("selectedMenuIds", allowedSelectedMenus);
            setValue("menuCategoryAndProductIds", newMenuCategoryIds);
        }
    });

    /** Some menus can be disabled as some users will not have access to them
     * For ease of use it will open the first index that isn't disabled
     */
    const startingTabIndex = menuOptions?.findIndex((opt: SelectOption) => !opt.isDisabled) ?? 0;

    /**
     * Show bottom part of menus if any are selected
     */
    const hasSelectedMenus = !!selectedMenuIds?.length;

    /**
     * any disabled menus - make sure the selections can not be cleared
     */
    const menuSelectionIsClearable = disallowedMenus.length === 0;

    return (
        <>
            {isFixedDiscount && <DiscountFormDates isDisabled={disableFormInputs} />}
            <RHSelectInput
                formLabel={translate("associatedMenus")}
                placeholder={translate("associatedMenus")}
                noOptionsMessage={() => translate("noAlternative")}
                name="selectedMenuIds"
                options={menuOptions}
                isClearable={menuSelectionIsClearable}
                isMulti
                isMandatory={isFixedDiscount}
                control={control}
            />

            {hasSelectedMenus && (
                <Tabs
                    paddingBottom={5}
                    minHeight="40vh"
                    variant="line"
                    themeColor="blue"
                    defaultIndex={startingTabIndex}
                    isVertical
                >
                    <TabList flexWrap="wrap" borderLeft="none">
                        {menuCategoryAndProductIds?.map((menu: MenuCategoryProducts) => {
                            const menuIsDisabled = disallowedMenus?.includes(menu.menuId) ?? false;
                            const hasSelectedItems = menuHasSelectedItems(menu);
                            const MenuNameAndId = menuOptions.find(opt => opt.value === menu.menuId);
                            const tagColour = menuIsDisabled
                                ? "orange.500"
                                : hasSelectedItems
                                ? "green.500"
                                : "red.500";
                            return (
                                <Tab
                                    key={menu.menuId}
                                    isDisabled={menuIsDisabled}
                                    justifyContent="start"
                                    textAlign={"left"}
                                >
                                    <Flex alignItems={"center"} minWidth="150px">
                                        <Indicator
                                            status={
                                                menuIsDisabled
                                                    ? "intermediary"
                                                    : hasSelectedItems
                                                    ? "active"
                                                    : "inactive"
                                            }
                                        />
                                        <Box ml={2}>{MenuNameAndId?.label}</Box>
                                    </Flex>
                                </Tab>
                            );
                        })}
                    </TabList>
                    <TabPanels position="relative">
                        {menuCategoryAndProductIds?.map((menu: MenuCategoryProducts) => {
                            const categoriesAndProducts = getMenuSelections(menu);
                            const MenuNameAndId = menuOptions.find(opt => opt.value === menu.menuId);
                            const menuIsDisabled = disallowedMenus?.includes(menu.menuId) ?? false;
                            return categoriesAndProducts ? (
                                <TabPanel
                                    key={menu.menuId}
                                    borderColor="gray.300"
                                    borderWidth="1px"
                                    borderStyle="solid"
                                    borderRadius="lg"
                                    minHeight="40vh"
                                    position="sticky"
                                    top="0px"
                                >
                                    <Box p={5}>
                                        <MenuProductsAndCategoriesTreeList
                                            menuProductCategories={categoriesAndProducts}
                                            selectedCategoryIds={menu.categoryIds}
                                            selectedProductIds={menu.productIds}
                                            onToggleCategory={(categoryId, isChecked) =>
                                                onToggleMenuCategory(categoryId, isChecked, MenuNameAndId?.value!)
                                            }
                                            onToggleProduct={(productId, isChecked) =>
                                                onToggleMenuProduct(productId, isChecked, MenuNameAndId?.value!)
                                            }
                                            showHasProductsMessage={true}
                                            alcoholProductIds={alcoholProductIds}
                                            isDisabled={menuIsDisabled}
                                        />
                                    </Box>
                                </TabPanel>
                            ) : null;
                        })}
                    </TabPanels>
                </Tabs>
            )}
        </>
    );
};
