import React, { useState } from "react";
import { useFormContext } from "react-hook-form";

import { SelectOption } from "Molecules";
import { Grid, RHCheckBoxInput, RHCreatableSelectInput, RHNumericInput } from "Atoms";
import { useLanguage } from "LanguageProvider";
import { DiscountWithMenus } from "../utils/DiscountTypes";

/** [EMAIL VARIABLES]
 *   Common Domains
 *   Regex to validate against
 *   Valid email function
 */
const emailDomains = ["@gmail.com", "@outlook.com", "@hotmail.com"];
const emailFormat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const isValidEmail = (email: string) => (email.match(emailFormat) ? true : false);

export const DiscountLimits: React.FC<{}> = ({}) => {
    const {
        control,
        formState: { defaultValues },
        getValues
    } = useFormContext<DiscountWithMenus>();
    const { translate } = useLanguage();
    const { isACopy } = getValues();

    /** if initial value is true then the check box will be disabled
     * Again due to admin changing discount options (2 options that are disabled)
     */
    const disableOneEmailLimit = (defaultValues?.oneEmailLimit && !isACopy) ?? false;
    const { code } = getValues();

    return (
        <>
            <Grid templateColumns="1fr 1fr" gap="1em">
                <RHNumericInput
                    name="maxRedeemed"
                    fullWidth
                    autoFocus
                    type="number"
                    placeholder={translate("maxUsage")}
                    formLabel={`${translate("maxUsage")} ${translate("zeroIsUnlimited")} - ${translate("online")} `}
                    isDisabled={!code}
                    control={control}
                />
                <RHNumericInput
                    name="noRedeemed"
                    fullWidth
                    type="number"
                    formLabel={`${translate("discountHasBeenUsed")} - ${translate("online")}`}
                    isDisabled={true}
                    control={control}
                />
            </Grid>
            <RHCheckBoxInput
                formLabel={`${translate("oneEmailPerDiscount")} - ${translate("online")}`}
                name="oneEmailLimit"
                control={control}
                isDisabled={disableOneEmailLimit}
            />
            <DiscountEmails />
        </>
    );
};

const DiscountEmails: React.FC<{}> = ({}) => {
    const { translate } = useLanguage();
    const {
        control,
        formState: { defaultValues },
        getValues
    } = useFormContext<DiscountWithMenus>();

    const [emailOptions, setEmailOptions] = useState<SelectOption[]>([]);
    const [inputValue, setInputValue] = useState<String>("");
    const [invalidEmail, setInvalidEmail] = useState<string | null>(null);

    const { isACopy } = getValues();

    /** These are initialEmails that are already set from the db
     * As we have had instances of discounts being changed, the behaviour
     * will now be 'once set a discount based on emails always a email discount'
     * Note: You can add more emails
     */
    const initialEmails =
        defaultValues?.allowedEmails?.map(opt => ({
            label: opt,
            value: opt,
            isFixed: isACopy ? false : true,
            isDisabled: isACopy ? false : true
        })) ?? [];

    /**
     * [FUNCTION] checking text is a valid email
     * Set email options to select with the most common domains and input value of user
     * Also present options in the select with the domain filled out with the input (used most common domains for emails)
     * @param input value inputted from user
     */
    const onEmailInput = (input: string) => {
        if (input.includes("@")) {
            setEmailOptions([]);
        } else {
            const emailIsValid = emailDomains.every((domain: string) => isValidEmail(`${input}${domain}`));
            if (!emailIsValid) {
                setInvalidEmail(translate("mustBeValidEmail"));
                setEmailOptions([]);
            } else {
                const values = emailDomains.map(value => ({
                    label: `${input}${value}`,
                    value: `${input.toLowerCase()}${value.toLowerCase()}`
                }));
                setEmailOptions(values);
                setInvalidEmail(null);
            }
        }
        setInputValue(input);
    };
    return (
        <RHCreatableSelectInput
            control={control}
            name="allowedEmails"
            formLabel={translate("emailAddresses")}
            placeholder={translate("typeInEmail")}
            noOptionsMessage={() => (invalidEmail ? invalidEmail : translate("noAlternative"))}
            onInputChange={onEmailInput}
            formatCreateLabel={input => `${translate("add")}...  ${input}`}
            isValidNewOption={(inputValue: string, _: any, __: any) => {
                return isValidEmail(inputValue);
            }}
            options={[...emailOptions, ...initialEmails]}
            onKeyDown={(event: React.KeyboardEvent) => {
                if (!inputValue.length) {
                    if (event.key === "Delete" || event.key === "Backspace") {
                        event.preventDefault();
                    }
                }
            }}
            isMulti
            isClearable={false}
        />
    );
};
